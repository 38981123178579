<template>
  <div class="base-grid">
    <div v-show="searchDisplay" class="base-grid__search">
      <div v-if="$slots.toolbar" class="base-grid__toolbar">
        <slot name="toolbar" />
      </div>
      <el-form
        v-if="params.length > 0 || $slots['toolbar-right']"
        ref="form"
        size="medium"
        :model="form"
        inline
        @submit.native.prevent
      >
        <template v-for="(item, index) in params">
          <el-form-item
            v-if="!item.hidden"
            :key="`params${index}`"
            :label="item.label"
            :prop="item.prop"
          >
            <el-select
              v-if="item.component === 'select'"
              v-model="form[item.prop]"
              clearable
              v-bind="omit(item, 'options')"
              :style="{ width: `${item.width}px` }"
              v-on="pickBy(item, val => typeof val === 'function')"
            >
              <el-option
                v-for="(option, oInedx) in item.options"
                :key="`${item.prop}${oInedx}`"
                v-bind="option"
              />
            </el-select>
            <el-radio-group
              v-else-if="item.component === 'radio'"
              v-model="form[item.prop]"
            >
              <el-radio
                v-for="option in item.options"
                :key="option.value"
                :label="option.value"
                >{{ option.label }}</el-radio
              >
            </el-radio-group>
            <el-date-picker
              v-else-if="item.component === 'date-picker'"
              :ref="item.prop"
              v-model="form[item.prop]"
              v-bind="omit(item)"
              :style="{ width: `${item.width}px` }"
              @hook:mounted="
                ($refs[item.prop][0].pickerVisible = true),
                  $nextTick(() => {
                    $refs[item.prop][0].pickerVisible = false;
                  })
              "
            />
            <component
              :is="
                typeof item.component === 'string'
                  ? `el-${item.component}`
                  : item.component
              "
              v-else-if="item.component !== undefined"
              v-model="form[item.prop]"
              v-bind="omit(item)"
              :style="{ width: `${item.width}px` }"
            />
            <el-input
              v-else
              v-model.trim="form[item.prop]"
              clearable
              v-bind="omit(item)"
              :style="{ width: `${item.width}px` }"
              @keyup.13.native="reload"
            />
          </el-form-item>
        </template>
        <el-form-item v-if="params.length > 0">
          <el-button type="primary" @click="reload">查询</el-button>
        </el-form-item>
        <el-form-item v-if="$slots['toolbar-right']">
          <slot name="toolbar-right" />
        </el-form-item>
      </el-form>
    </div>
    <div class="base-grid__area">
      <header>
        <slot name="header" />
      </header>
      <el-table
        ref="table"
        :border="noBorder"
        v-bind="$attrs"
        :data="data"
        :header-cell-class-name="headerCellClassName"
        v-on="$listeners"
      >
        <template v-for="(column, index) in columns">
          <template v-if="!column.hidden">
            <el-table-column
              v-if="column.slot === 'selection'"
              :key="index"
              type="selection"
              width="50"
              align="center"
              v-bind="omit(column, 'slot')"
            />
            <el-table-column
              v-else-if="column.slot === 'index'"
              :key="index"
              label="序号"
              type="index"
              width="50"
              align="center"
              :index="
                column.index ||
                  (index =>
                    index +
                    1 +
                    (pagination.currentPage - 1) * pagination.pageSize)
              "
              v-bind="omit(column, 'slot')"
            />
            <slot
              v-else-if="column.slot"
              :name="column.slot"
              :column="column"
            />
            <component
              :is="column.component"
              v-else-if="column.component"
              :key="column.prop"
              v-bind="column"
            />
            <el-table-column
              v-else-if="column.prop"
              :key="column.prop"
              :align="column.align || 'center'"
              :show-overflow-tooltip="
                column['show-overflow-tooltip'] === undefined
                  ? true
                  : column['show-overflow-tooltip']
              "
              v-bind="column"
            >
              <template v-slot="{ row }">{{
                {
                  undefined: () => get(row, column.prop),
                  function: () => column.convert(get(row, column.prop), row),
                  string: () =>
                    $root.$options.filters[column.convert](
                      get(row, column.prop)
                    )
                }[typeof column.convert]()
              }}</template>
            </el-table-column>
          </template>
        </template>
      </el-table>
      <div v-if="page" class="base-grid__pagination">
        <el-pagination
          popper-class="page-size-popper"
          background
          :layout="pagination.layout"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :current-page.sync="pagination.currentPage"
          :total="pagination.total"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, omit, pickBy, cloneDeep } from "lodash-es";

export default {
  name: "BaseGrid",
  inheritAttrs: false,
  props: {
    noBorder: {
      type: Boolean,
      default: false
    },
    source: {
      type: [Function, Array]
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    params: {
      type: Array,
      default() {
        return [];
      }
    },
    page: {
      type: Boolean,
      default: true
    },
    beforeFetch: {
      type: Function
    },
    afterFetch: {
      type: Function
    },
    autoFetch: {
      type: Boolean,
      default: true
    },
    headerCellClassName: {
      type: String,
      default: "blueColor"
    },
    searchDisplay: {
      type: Boolean,
      default: true
    },
    limit: Number
  },
  data() {
    return {
      form: {},
      data: [],
      pagination: {
        layout: "total, ->, prev, pager, next, sizes,jumper",
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 50, 100, 200]
      }
    };
  },
  computed: {
    isServer() {
      return typeof this.source === "function";
    }
  },
  watch: {
    source() {
      this.fetch();
    }
  },
  created() {
    this.params
      .filter(x => x.prop)
      .forEach(x => {
        this.$set(this.form, x.prop, x.value === 0 ? x.value : (x.value || ""));
      });
    if (this.limit) {
      this.pagination.pageSize = this.limit;
      if (!this.pagination.pageSizes.includes(this.limit)) {
        this.pagination.pageSizes.unshift(this.limit)
      }
    }
  },
  mounted() {
    this.autoFetch && this.columns.length > 0 && this.fetch();
  },
  methods: {
    pickBy(object, func) {
      return pickBy(object, func);
    },
    get(object, key) {
      return get(object, key);
    },
    omit(object, ...rest) {
      return omit(object, "prop", "component", "width", rest);
    },
    onSizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      this.pagination.currentPage = 1;
      this.fetch();
    },
    onCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.fetch();
    },
    async fetch() {
      let _data = this.source;
      const _params = cloneDeep(this.form);
      const _beforeFetch = this.beforeFetch
        ? this.beforeFetch(_params)
        : undefined;
      if (_beforeFetch === false) {
        return;
      }
      if (this.isServer) {
        const { data, totalCount } = await this.source({
          ...(_beforeFetch || _params),
          limit: this.page ? this.pagination.pageSize : undefined,
          page: this.page ? this.pagination.currentPage : undefined
        });
        if (this.page) {
          _data = data;
          this.pagination.total = totalCount;
        } else {
          _data = data;
        }
      } else {
        if (this.page) {
          _data = this.source.slice(
            (this.pagination.currentPage - 1) * this.pagination.pageSize,
            this.pagination.currentPage * this.pagination.pageSize
          );
          this.pagination.total = this.source.length;
        }
      }
      const _afterFetch = this.afterFetch ? this.afterFetch(_data) : undefined;
      this.data = _afterFetch || _data;
    },
    reload() {
      this.pagination.currentPage = 1;
      this.fetch();
    }
  }
};
</script>

<style lang="less" scoped>
.base-grid {
  .base-grid__area {
    background: #fff;
    padding: 12px;
    .el-button {
      border-radius: 4px;
    }
    > header:not(:empty) {
      margin-bottom: 12px;
    }
  }
  .base-grid__toolbar {
    height: 100%;
    padding: 5px 0;
    .el-alert {
      font-size: 0;
      height: 36px;
    }
  }
  .base-grid__search {
    &:not(:empty) {
      background: #fff;
      padding: 7px 12px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
    .el-button {
      height: 36px;
      line-height: 36px;
      padding: 0 20px;
      border-radius: 4px;
      margin-right: 0;
    }
    .el-form {
      flex: 1;
      text-align: right;
      .el-form-item {
        margin: 5px 0 5px 10px !important;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  ::v-deep {
    .blueColor {
      color: #000000;
      background: #F7F7F7 !important;
    }
    .el-button {
      margin-right: 0;
      border: none;
    }
    .clickable {
      cursor: pointer;
      color: #3d89fa;
      &:hover {
        color: #2160c1;
      }
    }
    .el-table__empty-block {
      min-height: 50px;
    }
    .el-table__empty-text {
      line-height: 50px;
    }
    .el-table__header {
      background: #edf3fd;
    }
    .el-table-column--selection .cell {
      display: flex;
      justify-content: center;
    }
    .el-table--border {
      border: 1px solid #ebeef5 !important;
      border-bottom: none !important;
    }
    .el-table-column--selection .cell {
      padding: 0 !important;
    }
    .cell {
      .el-button {
        overflow: visible;
        padding: 0;
        background: transparent;
        color: #3d89fa;
        &:hover {
          color: #2160c1;
        }
        &.is-disabled {
          color: #c0c4cc !important;
          background: transparent;
        }
      }
    }
    .el-date-editor .el-range-input {
      text-align: center;
    }
    .el-range-editor--medium.el-input__inner {
      height: 36px;
    }
    .el-pagination {
      margin-top: 12px;
    }
    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      transform: scale(0.5) translateY(-50%);
      top: 50%;
    }
  }
}
.el-dialog,
.el-collapse-item__content {
  .base-grid__search,
  .base-grid__area {
    padding: 0;
  }
}
</style>
